import { AppConfig } from './models/app-config';

export const config: AppConfig = {
  name: 'Avakompas',
  item: {
    code: {
      width: 150,
    },
    manufacturer: {
      width: 150,
    },
    itemGroup: {
      visible: false,
      width: 150,
    },
    quantity: {
      width: 80,
    },
    plannedQuantity: {
      visible: false,
      width: 110,
    },
    vendorQuantity: {
      visible: false,
      width: 80,
    },
    minimumOrderQuantity: {
      width: 110,
    },
    deliveryDate: {
      visible: false,
      width: 110,
    },
    packageAmount: {
      visible: false,
      width: 0,
    },
    unit: {
      width: 80,
    },
    netWeight: {
      visible: false,
      width: 0,
    },
    priceList: {
      visible: false,
      width: 180,
    },
    delivery: {
      visible: false,
    },
    priceWithoutVAT: {
      width: 128,
    },
    discount: {
      visible: false,
      width: 0,
    },
    price: {
      visible: false,
      width: 0,
    },
  },
  basket: {
    deliveryAddress: {
      visible: false,
    },
    urgentOrder: {
      visible: false,
    },
    item: {
      totalPrice: {
        width: 90,
      },
    },
  },
  invoice: {
    packingList: {
      visible: true,
    },
  },
};
